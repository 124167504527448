import React from 'react';
import './portfolio.css';
import p1 from '../../assets/MovieHub.jpeg';
import p3 from '../../assets/fitness-app.png';
import p4 from '../../assets/contact_safe.jpg';

const portfolioItems = [
  {
    image: p3,
    title: 'Personal Fitness Trainer',
    githubLink: 'https://github.com/GulzaibAhmad/fitness-app',
    liveDemoLink: 'https://fitness-19hbsw39p-gulzaibahmad.vercel.app/',
    languages: "JSX, CSS, React.js"
  },
  {
    image: p1,
    title: 'MovieHub',
    githubLink: 'https://github.com/GulzaibAhmad/moviehub',
    liveDemoLink: 'https://moviehub-ten.vercel.app/',
    languages: "JSX, CSS, React.js, OMDb API"
  },
  {
    image: p4,
    title: 'Contact Safe',
    githubLink: 'https://github.com/GulzaibAhmad/Contact_Safe',
    liveDemoLink: 'https://my-contacts-frontend-six.vercel.app/',
    languages: "JSX, CSS, React.js, Node.js, PostgreSQL"
  },
];

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="portfolio__container">
        {portfolioItems.map((item, index) => (
          <article key={index} className='portfolio__item'>
            <div className="portfolio__item-image">
              <img src={item.image} alt={`${item.title} img`} />
            </div>
            <h3>{item.title}</h3>
            <p>{item.languages}</p>
            <br />
            <div className='button'>
              <a href={item.githubLink} className='btn' rel="noreferrer" target='_blank'>Github</a>
              <a href={item.liveDemoLink} className='btn btn-primary' rel="noreferrer" target='_blank'>Live Demo</a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
}

export default Portfolio;
