import React from 'react'

import './nav.css'

import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import {BiMessageRoundedDots} from 'react-icons/bi'

import { useState } from 'react'

const Nav = () => {

  const [acitveNav, setactiveNav] = useState('#')

  return (
    <nav>
      <a href="#" onClick={() => setactiveNav('#')} className={acitveNav === '#' ? 'active' : ''}><AiOutlineHome/></a>
      <a href="#about" onClick={() => setactiveNav('#about')} className={acitveNav === '#about' ? 'active' : ''}><AiOutlineUser/></a>
      <a href="#experience" onClick={() => setactiveNav('#experience')} className={acitveNav === '#experience' ? 'active' : ''}><BiBook/></a>
      <a href="#services" onClick={() => setactiveNav('#services')} className={acitveNav === '#services' ? 'active' : ''}><RiServiceLine/></a>
      <a href="#contact" onClick={() => setactiveNav('#contact')} className={acitveNav === '#contact' ? 'active' : ''}><BiMessageRoundedDots/></a>
    </nav>
  )
}

export default Nav;