import React from 'react';
import './header.css';
import CTA from './CTA';
import ME from '../../assets/Main.png';
import HeaderSocial from './HeaderSocials';

const Header = () => {
  return (
    <header className="header">
      <div className="header_container">
        <h5>Hello, I'm</h5>
        <h1>Gulzaib Ahmad</h1>
        <h5 className="text-light">Full-Stack Web & App Developer</h5>
        <CTA />
        <HeaderSocial />
      </div>

      <div className="me">
        <img src={ME} alt="me" />
      </div>
    </header>
  );
};

export default Header;
