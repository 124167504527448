import React, { useState } from 'react';
import './contact.css';

const Contact = () => {
  const [message, setMessage] = useState('');
  const [from, setFrom] = useState('');

  const handleChange = (e) => {
    e.preventDefault();

    switch (e.target.name) {
      case 'message':
        setMessage(e.target.value);
        break;
      case 'from':
        setFrom(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (!from || !message) {
        throw new Error('Email and message fields are required.');
      }
  
      const response = await fetch('https://deployed-server.vercel.app/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          from,
          message
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to send message. Server returned ' + response.status);
      }
  
      alert('Message sent!');
      setMessage('');
      setFrom('');
    } catch (error) {
      console.error('Error:', error.message);
      alert('An error occurred while sending the message.');
    }
  };
  

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Us</h2>
      
      <div className="contact__container">
        <form onSubmit={handleSubmit} className="contact__form">
          <input
            type="text"
            name="from"
            placeholder="Your Email"
            value={from}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            id="message"
            rows="7"
            placeholder="Your Message"
            value={message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
