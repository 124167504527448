import React from 'react';
import './services.css';
import { BiCheck } from 'react-icons/bi';

const Services = () => {

  const servicesData = [
    {
      title: 'HTML, CSS',
      items: ['Landing Pages', 'Full Website', 'Contact Forms']
    },
    {
      title: 'JavaScript',
      items: ['Data Types', 'Loops', 'Methods']
    },
    {
      title: 'React',
      items: ['Props', 'Hooks', 'Components']
    }
  ];

  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        {servicesData.map((service, index) => (
          <article className="service" key={index}>
            <div className="service__head">
              <h3>{service.title}</h3>
            </div>

            <ul className="service__list">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>
                  <BiCheck className="service__list-icon" />
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Services;
