import React from "react";

import "./about.css";

import ME from "../../assets/about-removebg-preview.png";

import { FaAward } from "react-icons/fa";
import { VscFolderActive } from "react-icons/vsc";
import { MdCastForEducation } from "react-icons/md";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About img" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <MdCastForEducation className="about__icon" />
              <h5>Education</h5>
              <small>
                Software Engineer <br />
                (On Track)
              </small>
            </article>

            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>1+ Year Wroking</small>
            </article>
          </div>

          <p>
            Greetings! I'm Gulzaib Ahmad, a BS Software Engineering student
            offering a holistic approach to web development. Proficient in HTML,
            CSS, JavaScript, React, React Native, Node.js, Ruby on Rails and PostgreSQL. I excel in both
            frontend and backend aspects. My forte lies in crafting engaging
            websites that seamlessly blend style and functionality, ensuring
            responsive designs and interactive features for a captivating user
            experience. What sets me apart is my dedication to staying at the
            forefront of industry trends, ensuring that the solutions I deliver
            are innovative and effective. As a dynamic full stack developer, I'm
            eager to take on new challenges and bring your projects to life with
            expertise and enthusiasm. Let's connect and transform your ideas
            into reality!
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
