import React from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';

const frontendSkills = [
  { name: 'HTML' },
  { name: 'CSS' },
  { name: 'JavaScript' },
  { name: 'React.js' },
  { name: 'React Native' },
];

const backendSkills = [
  { name: 'Node.js' },
  { name: 'PostgreSQL' },
  { name: 'Ruby on Rails' },
];

const SkillItem = ({ name }) => (
  <article className="experience_details">
    <BsPatchCheckFill className='experience__details-icon' />
    <h4>{name}</h4>
  </article>
);

const SkillsSection = ({ title, skills }) => (
  <div className="experience_category">
    <h3>{title}</h3>
    <div className="experience_content">
      {skills.map((skill, index) => (
        <SkillItem key={index} name={skill.name} />
      ))}
    </div>
  </div>
);

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
        <div className="experience_frontend">
          <SkillsSection title="Frontend Development" skills={frontendSkills} />
        </div>
        
        <div className="experience_backend">
          <SkillsSection title="Backend Development" skills={backendSkills} />
        </div>
      </div>
    </section>
  );
}

export default Experience;
